import React from "react";
import "./App.css";

const App: React.FC = () => {
  return (
    <div className="App">
      <h1>
        This <i>is</i> a personal website.
      </h1>
    </div>
  );
};

export default App;
